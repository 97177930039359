//Genera palabras de referencia que se usan en todos los modulos y las encripta
export const TOKEN = "token";
export const MODULOS = "modulos";
export const PERMISOS = "permisos";
export const DATOS_USUARIO = "datosUser";
export const DATOS_MODULOS = {
	1: [0, 1], // "Administracion"
	2: [0, 2], // "Transacción"
	3: [0, 3], // "Procesos"
	4: [0, 4], // "Consultas"
	5: [0, 5], // "Pagos e incapacidades"
	6: [1, 6], // "Empresa"
	7: [1, 7], // "Usuarios"
	8: [1, 8], // "Actualizacion masiva"
	9: [6, 9], // "Asesor"
	10: [4, 21], // "Consulta Clientes"
	11: [6, 11], // "Datos de empresa"
	12: [6, 12], // "Chat"
	13: [6, 13], // "Clientes sin pago"
	14: [4, 11], // "Bitacora"
	15: [5, 19], // "Consolidado de pagos"
	16: [4, 14], // "Cierre incompleto"
	17: [3, 7], // "Cierre parcial"
	18: [3, 18], // "Cierre de periodo actual"
	19: [5, 20], // "Incapacidades"
	20: [5, 15], // "Planillas"
	21: [4, 16], // "Cierre de caja"
	22: [2, 22], // "Afiliacion"
	23: [2, 23], // "Pago asesor"
	24: [2, 24], // "Pago individual"
	25: [2, 25], // "Cierre de planillas"
	26: [5, 27], // "Pago de incapacidades"
	27: [5, 26], // "Consulta de cliente"
	28: [4, 28], // "Consulta de transacciones"
	29: [4, 29], // "Afiliacion de empresa"
	30: [2, 30], // "Conciliacion"
	31: [3, 31], // "SST"
	32: [3, 32], // "Cifras de interes"
	33: [1, 33], // "Listas"
	34: [6, 34], // "Clientes"
	35: [0, 35], // "Afiliaciones"
	36: [35, 36], // "Empresas"
	37: [35, 37], // "Afiliación solicitada"
	38: [35, 38], // "Afiliación cancelada"
	39: [35, 39], // "Afiliación procesada"
	40: [35, 40], // "Afiliación rechazada"
	41: [35, 41], // "Afiliación finalizada"
	42: [5, 42], // "Saldos"
	43: [6, 43], // "Nit"
	44: [4, 44], // "Consulta Recaudos"
	45: [4, 45], // "Consulta Planillas"
	46: [0, 46], // "Contabilidad"
	47: [46, 47], // "Facturación Siigo"
	48: [48, 48], // "Comprobantes"
	49: [1, 49], // "Anuncios"
	50: [4, 50], // "Ayudas"
	51: [0, 51], // "Seguro"
	52: [51, 52], // "Seguro Incapacidades"
};
