import { lazy, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom"; // Manejador de rutas
import { library } from "@fortawesome/fontawesome-svg-core"; // Libreria para importacion global
// import {
//	 fab
// } from "@fortawesome/free-brands-svg-icons"; // Iconos tipo fab
import {
	faUser,
	faLock,
	faEye,
	faEyeSlash,
	faBars,
	faXmark,
	faAngleDown,
	faSearch,
	faArrowsRotate,
	faSliders,
	faAnglesLeft,
	faAnglesRight,
	faChevronLeft,
	faChevronRight,
	faChevronDown,
	faUserTie,
	faBuilding,
	faBusinessTime,
	faTableColumns,
	faPersonCane,
	faHandHoldingMedical,
	faHelmetSafety,
	faPeopleGroup,
	faCommentDots,
	faCircleQuestion,
	faCircleInfo,
	faGlobe,
	faWrench,
	faPlus,
	faCloudUploadAlt,
	faTimesCircle,
	faClipboardList,
	faMoneyCheckDollar,
	faAddressBook,
	faIdCardClip,
	faCity,
	faMoneyBills,
	faCashRegister,
	faUsersRays,
	faUsersSlash,
	faClipboardCheck,
	faBan,
	faBriefcase,
	faRectangleList,
	faUserPlus,
	faFileInvoiceDollar,
	faFileExcel,
	faSackXmark,
	faPrint,
	faFile,
	faFilePdf,
	faAddressCard,
	faFileInvoice,
} from "@fortawesome/free-solid-svg-icons"; // Iconos que estaran disponibles de manera global
// import { SocketProvider } from "context/socketContext";
import PrivateRoute from "components/privatePath/privateRoute"; // Confirma la sesion actual del usuario
import PrivateModule from "components/privatePath/privateModules"; // Confirma si el usuario tiene permiso para el modulo

/* --------------------------------------------------------
Pagina de carga al renderizar
-------------------------------------------------------- */
import Loading from "pages/public/Loading";
import { useSelector } from "react-redux";
/* --------------------------------------------------------
Pagina de carga al renderizar
-------------------------------------------------------- */

/* --------------------------------------------------------
Modales
-------------------------------------------------------- */
// import Load from "components/alerts/load";
const Load = lazy(() => import("components/alerts/load"));
// import Alert from "components/alerts/alert";
const Alert = lazy(() => import("components/alerts/alert"));
// import ModalOptionsUser from "components/modal/modalOptionsUser";
const ModalOptionsUser = lazy(() => import("components/modal/modalOptionsUser"));
// import ModalAsociarPerfil from "components/modal/modalAsociarPerfil";
const ModalAsociarPerfil = lazy(() => import("components/modal/modalAsociarPerfil"));
// import ModalLogin from "components/modal/modalLogin";
const ModalLogin = lazy(() => import("components/modal/modalLogin"));
/* --------------------------------------------------------
Modales
-------------------------------------------------------- */

/* --------------------------------------------------------
Paginas publicas
-------------------------------------------------------- */
// import Login from "pages/public/Login";
const Login = lazy(() => import("pages/public/Login"));
/* --------------------------------------------------------
Paginas publicas
-------------------------------------------------------- */

/* --------------------------------------------------------
Paginas Privadas
-------------------------------------------------------- */
const Body = lazy(() => import("pages/private/body"));
const PrivateMenu = lazy(() => import("pages/private/menu"));
const PrivateInicio = lazy(() => import("pages/private/inicio"));
const PrivateUsuarios = lazy(() => import("pages/private/usuarios"));
const PrivateClientes = lazy(() => import("pages/private/clientes"));
const PrivateAsesor = lazy(() => import("pages/private/asesor"));
const PrivateActualizacionMasica = lazy(() => import("pages/private/actualizacionMasiva"));
const PrivatePagoIndividual = lazy(() => import("pages/private/pagoIndividual"));
const PrivateCierreParcial = lazy(() => import("pages/private/cierreParcial"));
const PrivatePlanillas = lazy(() => import("pages/private/planillas"));
const PrivateEmpresas = lazy(() => import("pages/private/empresas"));
const PrivateAfiliacionSolicitada = lazy(() => import("pages/private/afiliacionSolicitada"));
const PrivateAfiliacionCancelada = lazy(() => import("pages/private/afiliacionCancelada"));
const PrivateAfiliacionProcesada = lazy(() => import("pages/private/afiliacionProcesada"));
const PrivateAfiliacionRechazada = lazy(() => import("pages/private/afiliacionRechazada"));
const PrivateAfiliacionFinalizada = lazy(() => import("pages/private/afiliacionFinalizada"));
const PrivateBitacora = lazy(() => import("pages/private/bitacora"));
const PrivatePagoAsesor = lazy(() => import("pages/private/pagoAsesor"));
const PrivateCierreCaja = lazy(() => import("pages/private/cierreCaja"));
const PrivateCierrePeriodoActual = lazy(() => import("pages/private/cierrePeriodoActual"));
const PrivateAfiliacion = lazy(() => import("pages/private/afiliacion"));
const PrivateCierreIncompleto = lazy(() => import("pages/private/cierreIncompleto"));
const PrivateNit = lazy(() => import("pages/private/nit"));
const PrivateClientesSinPago = lazy(() => import("pages/private/clientesSinPago"));
const PrivateConciliacion = lazy(() => import("pages/private/conciliacion"));
const PrivateConsultaRecaudos = lazy(() => import("pages/private/consultaRecaudos"));
const PrivateConsultaPlanillas = lazy(() => import("pages/private/consultaPlanillas"));
const PrivateListas = lazy(() => import("pages/private/listas"));
const PrivateCifrasInteres = lazy(() => import("pages/private/cifrasInteres"));
const PrivateDatosEmpresa = lazy(() => import("pages/private/datosEmpresa"));
const PrivateFacturaSiigo = lazy(() => import("pages/private/facturaSiigo"));
const PrivateSst = lazy(() => import("pages/private/sst"));
const PrivateAnuncios = lazy(() => import("pages/private/anuncios"));
const PrivateCierrePlanillas = lazy(() => import("pages/private/cierrePlanillas"));
const PrivateAyudas = lazy(() => import("pages/private/ayudas"));
const PrivateIncapacidades = lazy(() => import("pages/private/incapacidades"));
const PrivateSeguros = lazy(() => import("pages/private/seguros"));

/* ------------------------------------------------------
Paginas Privadas
-------------------------------------------------------- */

library.add(
	// fab,
	faUser,
	faLock,
	faEye,
	faEyeSlash,
	faBars,
	faXmark,
	faAngleDown,
	faSearch,
	faArrowsRotate,
	faSliders,
	faAnglesLeft,
	faAnglesRight,
	faChevronLeft,
	faChevronRight,
	faChevronDown,
	faUserTie,
	faBuilding,
	faBusinessTime,
	faTableColumns,
	faPersonCane,
	faHandHoldingMedical,
	faHelmetSafety,
	faPeopleGroup,
	faCommentDots,
	faCircleQuestion,
	faCircleInfo,
	faGlobe,
	faWrench,
	faPlus,
	faCloudUploadAlt,
	faTimesCircle,
	faClipboardList,
	faMoneyCheckDollar,
	faAddressBook,
	faIdCardClip,
	faCity,
	faMoneyBills,
	faCashRegister,
	faUsersRays,
	faUsersSlash,
	faClipboardCheck,
	faBan,
	faBriefcase,
	faRectangleList,
	faUserPlus,
	faFileInvoiceDollar,
	faFileExcel,
	faSackXmark,
	faPrint,
	faFile,
	faFilePdf,
	faAddressCard,
	faFileInvoice
); // Importa los iconos a la libreria y que esten disponibles globalmente

const App = () => {
	const { session } = useSelector((state) => state.configSession);

	return (
		<Suspense fallback={<Loading />}>
			<Alert />
			<Load />

			<Routes>
				<Route exact path="/" element={session ? <Navigate to="/private/inicio" /> : <Login />}></Route>

				<Route exact path="/login" element={session ? <Navigate to="/private/inicio" /> : <Login />}></Route>

				{/* Rutas protegidas en la cual cualquier ruta se valida si ya inicio sesion */}
				<Route
					path="/*"
					element={
						<PrivateRoute>
							<Body>
								<PrivateMenu />
								{/* Menu para todas las paginas */}

								<ModalOptionsUser />
								{/* Ventana modal para formularios que manejaran sus propias peticiones y recursos */}

								<ModalLogin />
								{/* Ventana modal para loguear al usuario */}

								<ModalAsociarPerfil />
								{/* Ventana modal para que se asocien las cuentas del usuario */}

								<Routes>
									{/*
											TODAS LAS RUTAS QUE CONTIENEN LA PALABRA PRIVATE, SE CONFIRMA SI EL USUARIO
											TIENE EL MODULO ASIGNADO, DADO POR EL SERVIDOR
										*/}
									<Route exact path="/private/inicio" element={<PrivateInicio />}></Route>

									<Route
										exact
										path="/private/usuarios"
										element={
											<PrivateModule>
												<PrivateUsuarios />
											</PrivateModule>
										}
									></Route>

									<Route
										exact
										path="/private/clientes"
										element={
											<PrivateModule>
												<PrivateClientes />
											</PrivateModule>
										}
									></Route>

									<Route
										exact
										path="/private/asesor"
										element={
											<PrivateModule>
												<PrivateAsesor />
											</PrivateModule>
										}
									></Route>

									<Route
										exact
										path="/private/actualizacionMasiva"
										element={
											<PrivateModule>
												<PrivateActualizacionMasica />
											</PrivateModule>
										}
									></Route>

									<Route
										exact
										path="/private/pagoIndividual"
										element={
											<PrivateModule>
												<PrivatePagoIndividual />
											</PrivateModule>
										}
									></Route>

									<Route
										exact
										path="/private/cierreParcial"
										element={
											<PrivateModule>
												<PrivateCierreParcial />
											</PrivateModule>
										}
									></Route>

									<Route
										exact
										path="/private/planillas"
										element={
											<PrivateModule>
												<PrivatePlanillas />
											</PrivateModule>
										}
									></Route>

									<Route
										exact
										path="/private/empresas"
										element={
											<PrivateModule>
												<PrivateEmpresas />
											</PrivateModule>
										}
									></Route>

									<Route
										exact
										path="/private/afiliacionSolicitada"
										element={
											<PrivateModule>
												<PrivateAfiliacionSolicitada />
											</PrivateModule>
										}
									></Route>

									<Route
										exact
										path="/private/afiliacionCancelada"
										element={
											<PrivateModule>
												<PrivateAfiliacionCancelada />
											</PrivateModule>
										}
									></Route>

									<Route
										exact
										path="/private/afiliacionProcesada"
										element={
											<PrivateModule>
												<PrivateAfiliacionProcesada />
											</PrivateModule>
										}
									></Route>

									<Route
										exact
										path="/private/afiliacionRechazada"
										element={
											<PrivateModule>
												<PrivateAfiliacionRechazada />
											</PrivateModule>
										}
									></Route>

									<Route
										exact
										path="/private/afiliacionFinalizada"
										element={
											<PrivateModule>
												<PrivateAfiliacionFinalizada />
											</PrivateModule>
										}
									></Route>

									<Route
										exact
										path="/private/bitacora"
										element={
											<PrivateModule>
												<PrivateBitacora />
											</PrivateModule>
										}
									></Route>

									<Route
										exact
										path="/private/pagoAsesor"
										element={
											<PrivateModule>
												<PrivatePagoAsesor />
											</PrivateModule>
										}
									></Route>

									<Route
										exact
										path="/private/cierreCaja"
										element={
											<PrivateModule>
												<PrivateCierreCaja />
											</PrivateModule>
										}
									></Route>

									<Route
										exact
										path="/private/cierrePeriodoActual"
										element={
											<PrivateModule>
												<PrivateCierrePeriodoActual />
											</PrivateModule>
										}
									></Route>

									<Route
										exact
										path="/private/afiliacion"
										element={
											<PrivateModule>
												<PrivateAfiliacion />
											</PrivateModule>
										}
									></Route>

									<Route
										exact
										path="/private/cierreIncompleto"
										element={
											<PrivateModule>
												<PrivateCierreIncompleto />
											</PrivateModule>
										}
									></Route>

									<Route
										exact
										path="/private/nit"
										element={
											<PrivateModule>
												<PrivateNit />
											</PrivateModule>
										}
									></Route>

									<Route
										exact
										path="/private/clientesSinPago"
										element={
											<PrivateModule>
												<PrivateClientesSinPago />
											</PrivateModule>
										}
									></Route>

									<Route
										exact
										path="/private/conciliacion"
										element={
											<PrivateModule>
												<PrivateConciliacion />
											</PrivateModule>
										}
									></Route>

									<Route
										exact
										path="/private/consultaRecaudos"
										element={
											<PrivateModule>
												<PrivateConsultaRecaudos />
											</PrivateModule>
										}
									></Route>

									<Route
										exact
										path="/private/consultaPlanillas"
										element={
											<PrivateModule>
												<PrivateConsultaPlanillas />
											</PrivateModule>
										}
									></Route>

									<Route
										exact
										path="/private/listas"
										element={
											<PrivateModule>
												<PrivateListas />
											</PrivateModule>
										}
									></Route>
									<Route
										exact
										path="/private/cifrasInteres"
										element={
											<PrivateModule>
												<PrivateCifrasInteres />
											</PrivateModule>
										}
									></Route>

									<Route
										exact
										path="/private/datosEmpresa"
										element={
											<PrivateModule>
												<PrivateDatosEmpresa />
											</PrivateModule>
										}
									></Route>

									<Route
										exact
										path="/private/facturaSiigo"
										element={
											<PrivateModule>
												<PrivateFacturaSiigo />
											</PrivateModule>
										}
									></Route>

									<Route
										exact
										path="/private/sst"
										element={
											<PrivateModule>
												<PrivateSst />
											</PrivateModule>
										}
									></Route>

									<Route
										exact
										path="/private/anuncios"
										element={
											<PrivateModule>
												<PrivateAnuncios />
											</PrivateModule>
										}
									></Route>

									<Route
										exact
										path="/private/cierrePlanillas"
										element={
											<PrivateModule>
												<PrivateCierrePlanillas />
											</PrivateModule>
										}
									></Route>

									<Route
										exact
										path="/private/ayudas"
										element={
											<PrivateModule>
												<PrivateAyudas />
											</PrivateModule>
										}
									></Route>

									<Route
										exact
										path="/private/incapacidades"
										element={
											<PrivateModule>
												<PrivateIncapacidades />
											</PrivateModule>
										}
									></Route>

									<Route
										exact
										path="/private/seguroIncapacidades"
										element={
											<PrivateModule>
												<PrivateSeguros />
											</PrivateModule>
										}
									></Route>

									<Route path="/private/*" element={<Navigate to="/private/inicio" />}></Route>
								</Routes>
							</Body>
						</PrivateRoute>
					}
				></Route>
			</Routes>
		</Suspense>
	);
};

export default App;
